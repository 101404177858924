<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { strUppercaseFirstLetter, strLimitLength, formatNewlineToHtml } from 'dvn-js-utils'
import { localeLink } from '#imports'
import type { PopulatedInstrument } from '~~/types'

const props = defineProps<{
  instrument: PopulatedInstrument
  height: number
  width: number
}>()

const appContext = useAppContext()
const { smAndDown } = useDisplay()
</script>

<template>
  <VCarouselItem :height="props.height" :width="props.width" cover eager>
    <div class="container">
      <div class="layout" :class="{ sm: smAndDown }">
        <CatalogInstrumentCover
          :instrument="props.instrument"
          display="large"
          play-position="center"
          class="cover"
          :class="{ sm: smAndDown }"
        />
        <NuxtLink
          :to="localeLink(`${appContext.catalogPath}/instruments/${props.instrument._id}`)"
          class="d-flex-column carousel-link"
        >
          <!-- eslint-disable-next-line vuetify/no-deprecated-classes -->
          <div class="text-gradient title" :class="{ sm: smAndDown }">
            {{ props.instrument.name }}
          </div>
          <div
            class="description d-none d-md-block"
            v-html="
              strLimitLength(
                strUppercaseFirstLetter(formatNewlineToHtml(props.instrument.description)),
                500,
              )
            "
          ></div>
        </NuxtLink>
      </div>
      <CatalogInstrumentRelatedVideos :instrument="props.instrument" />
    </div>
  </VCarouselItem>
</template>

<style scoped>
.container {
  background-image: linear-gradient(to right, transparent 50%, rgba(255, 255, 255, 0.05));
  border-radius: 0.4em;
  /* background-color: green; */
}
.layout {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  padding: 0;
  margin: 0;
}
.layout.sm {
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* background-color: red; */
  margin-bottom: 0.2em;
}
.cover {
  padding: 0;
  margin: 0.5em;
}
.cover.sm {
  margin: 0;
}
.title {
  margin: 0 0.4em;
  font-size: 1.5em;
  font-weight: 600;
}
.title.sm {
  margin: 0.4em 0;
  font-size: 1.2em;
}

a.carousel-link {
  color: rgba(var(--dvn-color-text), 1);
}

a.carousel-link:hover {
  opacity: 0.8;
}

.description {
  margin: 0.6em 1.2em;
}
</style>
